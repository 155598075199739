.wrapper {
  display: flex;
  height: 80px;
  align-items: flex-end;
}

.wrapper p {
  font-size: 2.5rem;
  padding-left: 50px;
}
