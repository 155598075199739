.wrapper {
  margin-top: 4rem;
  display: flex;
  flex-grow: 0.5;
  flex-direction: column;
  justify-content: flex-start;
  
}

.quick-data {
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  height: 100%;
  
  
}

@media screen and (max-width: 1440px) {
  .wrapper {
    margin-top: 3.5rem;
  }
  .quick-data {
    display: grid;
    padding-top: 2rem;
    grid-template-columns: repeat(2, 1fr);
    grid-template-rows: repeat(2, 1fr);
    align-items: center;
    justify-items: center;
  }
}

