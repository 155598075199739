.app-wrapper {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100vw;
  height: 100vh;
  background-color: #c4c5c8;
  overflow-x: hidden;
}

.app {
  position: relative;

  width: 80%;
  height: 80%;
  display: flex;
  flex-direction: row;
  border-radius: 40px;
  background-color: #fff;
}
@media screen and (max-width: 1440px) {
  .app {
    display: flex;
    flex-direction: column;
    border-radius: 40px;
    width: 100vw;
    height: 100vh;
    border-radius: 0;
  }
}



