.wrapper {
  height: 100px;
  display: flex;
  align-items: center;
}

.wrapper sup {
  font-size: 2rem;
  left: 100px;
  bottom: 50px;
}

.content {
  font-size: 3rem;
  padding-left: 50px;
}
