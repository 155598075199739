.wrapper {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  gap: 3rem;
}

@media screen and (max-width: 900px) {
  .wrapper {
    width: 100%;
    justify-content: space-between;
    gap: 20px;
    overflow-x: auto;
  }
}
