.wrapper {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 10px;
}

.wrapper input {
    background: none;
    border: none;
    outline: none;
    padding-left: 2rem;
}

@media screen and (max-width: 481px) {
    .wrapper {
        gap: 2px;
    }

    .wrapper input {
       width: 12rem;
    }
}