.wrapper img{
    width: 10rem;
    padding-left: 80px  
}

@media screen and (max-width: 481px) {
    .wrapper img{
        width: 8rem;
        padding-left: 0;
    }
}