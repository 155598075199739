.wrapper {
  display: flex;
  justify-content: space-between;
  padding: 0 60px;
}

.forcast-type {
  display: flex;
  gap: 10px;
}

.forcast-type button {
  font-weight: bold;
  color: #b0b0b0;
}

.forcast-type--active {
  text-decoration: underline;
  text-underline-position: under;
  color: black;
}

.metric-type {
  display: flex;
  gap: 0.5rem;
 
}

.metric-type--selected {
  background-color: grey;
  font-size: 0.5rem;
}

@media screen and (max-width: 481px) {
  .wrapper {
    padding: 0 20px;
  }

  .forcast-type {
    display: flex;
    gap: 5px;
  }
}
