.wrapper {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  gap: 1rem;
}

@media screen and (max-width: 900px) {
  .wrapper {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    gap: 1rem;
    overflow-x: auto;
  }
}
