.wrapper {
  display: flex;
  padding-top: 10px;
  flex-direction: column;
  background-color: #fff;
  width: 70%;
  height: 10rem;
  border-radius: 14px;
}

.title {
  font-size: 1.2rem;
  padding-left: 20px;
  padding-top: 10px;
  color: #b0b0b0
}

@media screen and (max-width: 481px) {
  .wrapper {
    background-color: #fff;
    width: 14rem;
    height: 8rem;
    border-radius: 14px;
  }
  
  .title {
   font-size: 20px;
  }
}